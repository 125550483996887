import { useEffect, useRef, useState } from "react";
import { Field, ErrorMessage } from "formik";
import { FormikValues } from "formik";

const Step4 = ({ setFieldValue, values, setFieldTouched }: FormikValues) => {
    const fileRef = useRef<HTMLInputElement>(null);
    const [image, setImage] = useState<File | null>();
    const [preview, setPreview] = useState<string | null | undefined>();

    useEffect(() => {
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(image);
        } else {
            setPreview(null);
        }
    }, [image]);
    let title = values?.idProofName;

    const handleFileChange = async (event: any) => {
        const file = event.target.files[0];
        if (file && file.type.substr(0, 11) === "application") {
            await setFieldValue("proofLocation", file);
            await setFieldTouched("proofLocation"); // Trigger validation immediately
            setImage(file);
        } else {
            setFieldValue("proofLocation", null);
            setImage(null);
        }
    };

    return (
        <div className="w-100">
            <div className="pb-10 pb-lg-12">
                <h2 className="fw-bolder text-dark">Additional Details</h2>
            </div>
            <div className="fv-row mb-10">
                <label className="form-label required">Proof Name</label>
                <Field
                    data-testid="id-proof-name"
                    as="select"
                    name="idProofName"
                    className="form-select form-select-lg form-select-solid"
                >
                    <option value="">Select your proof type</option>
                    <option value="Aadhar Card">Aadhar Card</option>
                    <option value="Voter Card">Voter Card</option>
                </Field>

                <div className="text-danger mt-2">
                    <ErrorMessage name="idProofName" />
                </div>
            </div>
            <div className="fv-row mb-10">
                <label className="form-label required">{title} Details</label>

                <Field
                    data-testid="id"
                    name="id"
                    placeholder={`Please enter the ${title} Details`}
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="id" />
                </div>
            </div>
            <div className="fv-row mb-10">
                <label className="form-label required">Address Site Name</label>
                <Field
                    data-testid="address-site-name"
                    name="addressSiteName"
                    placeholder="Enter your address name"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="addressSiteName" />
                </div>
            </div>

            <div className="fv-row mb-10">
                <label className="form-label required">Upload File</label>

                <div className="cursor-pointer d-flex col-lg-12">
                    <div className="d-flex col-lg-6 justify-content-between align-items-center">
                        <input
                            data-testid="proof-location"
                            ref={fileRef}
                            type="file"
                            className="form-control form-control-lg  form-control-solid"
                            accept="application/*"
                            onChange={handleFileChange}
                        />
                    </div>
                </div>

                <div className="text-danger mt-2">
                    <ErrorMessage name="proofLocation" />
                </div>
            </div>
        </div>
    );
};

export { Step4 };
